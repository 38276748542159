import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import COUNTRY_CODE from 'constants/countryCode';
import WINDOW_OBJECT_KEYS from 'constants/windowObjectKeys';
/*
 * Access to application configuration
 * NOTE: Temporary state
 */
const ehi = window.ehi || {};

const config = {
  path_name: window.location.pathname,
  locationSessionStorageKey: 'LOCATION_SEARCH',
  locationRecentSearchesKey: 'LOCATION_RECENT_SEARCHES',
  goToSpecificPagePath: 'GO_TO_SPECIFIC_PAGE_PATH',
  reservationAssociatedToProfile: 'RESERVATION_ASSOCIATED_TO_PROFILE',
  dealsEmailSpecialsModal: 'DEALS_EMAIL_SPECIALS_MODAL',
};

/**
 * GMI Config
 */

const GMI_CONFIG = (({ i18n = {}, site = {} } = {}) => {
  const { language: LANGUAGE = '', locale: LOCALE = '', date_time_format: DATE_TIME_FORMAT = {} } = i18n;
  const { gmaUrl: GMA_ORIGIN_URL, locationSearchUrl: SOLR_ORIGIN_URL, locationSearchApiKey: SOLR_API_KEY } = site;

  return {
    BRAND: 'ALAMO',
    CHANNEL: 'WEB',
    COUNTRY: ehi?.aem?.domain_country || 'US',
    GMA_HEADERS: { TAB_ID: gmiUtils.generateBrowserTabId() },
    RECAPTCHA_SITE_KEY: ehi?.site?.reCaptchaV2PublicKey,

    GMA_ORIGIN_URL,
    SOLR_ORIGIN_URL: `${SOLR_ORIGIN_URL}search/location/`,
    SOLR_API_KEY,
    DATE_TIME_FORMAT,
    LANGUAGE,
    LOCALE,
    SET_HTML_LANG: false,
  };
})(ehi);

config.getGMIConfig = () => GMI_CONFIG;

const splitPageHash = (url = '') => {
  if (url.includes('#')) {
    const [urlBase] = url.split('#');
    return urlBase;
  }
  return url;
};

/**
 * getUrl - returns requested site URL from configuration object
 *
 * @param {String} key Key of the desired URL
 *
 * @returns {String} Url
 */
config.getUrl = (key) => ehi.site?.[key];
config.getRedirectUrl = (key) => ehi.aem?.redirects?.[key];
config.getGoogleMapsAPIKey = () => ehi.site?.googleMapsApiKey;
config.getStack = () => ehi.aem?.stack;
config.getI18nTranslation = (key) => ehi.i18n?.content?.[key] || null;
config.getDateTimeFormat = (key) => ehi.i18n?.date_time_format?.long_date_format?.[key];
config.getDateTimeShortFormat = (key) => ehi.i18n?.date_time_format?.short_date_format?.[key];
config.getDateTimeLongFormat = (key) => ehi.i18n?.date_time_format?.long_date_format?.[key];
config.getWeek = (key) => ehi.i18n?.date_time_format?.week?.[key];
config.getContextPath = () => ehi.aem?.context_path;
config.getPageInfo = () => ehi.aem?.page_info;
config.getPagePath = () => config.getPageInfo()?.page_path;
config.getResourcePath = () => config.getPageInfo()?.resource_path;
config.getSiteSection = () => config.getPageInfo()?.site_section;
config.getLocale = () => ehi.i18n?.locale || '';
config.getLanguage = () => ehi.i18n?.language || '';
config.getIsGlobalGatewayEnabled = () => ehi.page?.enable_global_gateway;
config.getGlobalGatewayConfig = () => ehi.site?.globalGateway;
config.getShowAdChoices = () => ehi.aem?.show_ad_choices;
config.getAdChoicesUrl = () => ehi.aem?.ad_choices_url;
config.getIsIncrementalPricingEnabled = () => ehi.aem?.incremental_pricing;
config.getDomainCountry = () => ehi.aem?.domain_country;
config.getIsEnableAlamoInsider = () => ehi.site?.enableAlamoInsider;
config.getDealsModalSourceCode = () => ehi.aem?.deals_modal_source_code;
config.getIsEnableFlightDelay = () => ehi.site?.enableFlightDelayFeature;
config.getIsEnableCurrencyToggle = () => ehi.site?.enableCurrencyToggleFeature;
config.getIsEnableAssociateReservationToggleFeature = () => ehi.site?.enableAssociateReservationToggleFeature;
config.getIsEnabledMobileWalletIntegration = () => ehi.site?.enableMobileWalletIntegration;
config.getIsEnableAdChoiceIcon = () => ehi.site?.enableAdChoiceIcon;
config.getIsEnablePrivacyChoiceIcon = () => ehi.site?.enablePrivacyChoiceIcon;
config.getIsEnableFeaturedVehicle = () => ehi.site?.enableFeaturedVehicle;
config.isEnabledSmsOptIn = () => ehi.site?.enableSmsOptIn;
config.isEnabledEuImpliedConsent = () => ehi.site?.enableEuImpliedConsent;

// https://jira.ehi.com/browse/AWR-5558 => if ehi.site.soldOutTrueAvailability = TRUE  AND ehi.mvt.disableSelloutExperience != TRUE then show sellout experience
config.getIsSoldOutTrueAvailability = () =>
  ehi.site?.resFlow?.sold_out_true_availability && !ehi?.mvt?.disableSelloutExperience;
config.getIsCustomPathEnabled = () => ehi.site?.resFlow?.enable_custom_path_check_in;
config.getRecaptchaV2SiteKey = () => ehi.site?.reCaptchaV2PublicKey;
config.getAuthenticationRedirects = () => {
  const { authenticated_redirects, unauthenticated_redirects } = ehi.aem || {};
  return { authenticated_redirects, unauthenticated_redirects };
};
config.getIsAuthorModeEnabled = () => ehi.aem?.run_modes?.includes('author');
config.getSelectorString = () => ehi.aem?.selector_string;
config.getEmailSourceCode = () => ehi.aem?.email_source_code;
config.getEmailUnsubscribeSourceCode = () => ehi.aem?.email_unsubscribe_source_code;
config.isUnitedStatesDomain = () => config.getDomainCountry() === COUNTRY_CODE.US;
config.isCanadianDomain = () => config.getDomainCountry() === COUNTRY_CODE.CA;
config.isGBDomain = () => config.getDomainCountry() === COUNTRY_CODE.GB;
config.isNADomain = () => config.isUnitedStatesDomain() || config.isCanadianDomain();
config.getSiteLogoImage = () => ehi.aem?.site_logo_image_path;
config.getSiteLogoLink = () => ehi.aem?.site_logo_link;
config.isAuthHomePage = () =>
  config.getRedirectUrl(WINDOW_OBJECT_KEYS.AUTH_HOME_PAGE) === `${config.getPagePath()}.html`;
config.isCustomPathFlow = () =>
  config.getRedirectUrl(WINDOW_OBJECT_KEYS.CUSTOM_PATH_FLOW) === `${config.getPagePath()}.html`;
config.getVirginBrandInformation = () => ehi.aem?.custom_path_check_in?.virgin;
config.getCaasTags = () => ehi.aem?.caas;
config.isInExpectedSPAFlow = (pageKey) =>
  config.getRedirectUrl(WINDOW_OBJECT_KEYS[pageKey]) === `${splitPageHash(config.getPagePath())}.html`;
// timeout configs
config.getTimeoutPages = () => ehi.aem?.timeout_pages;
config.getTimeoutMinutes = () => ehi.site?.timeoutMinutes;
config.getGcsIsoFlagsPath = () =>
  ehi.site?.gcsIsoFlags || 'https://assets.gcs.ehi.com/content/dam/gcs/media/iso-flags/svg/';

config.isInTimeoutExpectedSPAFlow = () => {
  const timeoutPagesObject = config.getTimeoutPages();
  let value = false;
  timeoutPagesObject &&
    Object.keys(timeoutPagesObject).forEach((key) => {
      if (timeoutPagesObject[key] === `${splitPageHash(config.getPagePath())}.html`) {
        value = true;
      }
    });
  return value;
};

config.getTimeoutMinutesInMilliSeconds = (minutes) => minutes * 60 * 1000;

// Feature Flags Configs
config.getEnableConfirmModifyReturn = () => ehi.feature_flags?.enableConfirmModifyReturn;

config.getEnableConfirmModifyReturnUnauthLookup = () => ehi.feature_flags?.enableConfirmModifyReturnUnauthLookup;

export { config };
