import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import { i18n } from 'utils/i18n';
import { config } from 'utils/config';
import { getCurrentPageHash } from 'utils/url';
import RESERVATIONS from 'constants/reservations';
import RENTAL from 'constants/rental';

const { PAYMENT } = RESERVATIONS;

/**
 * Checks if the `site_section` is set to reservation flow. This can be used
 * in cases where we need to identify we are inside the resflow.
 */
export const isReservationFlow = () => config.getSiteSection() === RESERVATIONS.SITE_SECTION.RESERVE;

//  This checks to see if we are at the res flow review page
//  to do this we look at the hash of the URL
export const isInResFlowReviewPage = () => {
  const currentStepHash = getCurrentPageHash();
  return currentStepHash === RESERVATIONS.RESFLOW_PATHS_CONFIG.review[0];
};

/**
 * @name getFormattedContractRestrictionDates
 * @desc Convert contract timestamps into moment objects
 * @param {Object} contract
 * @returns {Object}
 */
export const getFormattedContractRestrictionDates = (restrictions, isPlanAhead = false) => {
  // restrictions object will have different naming convention depending if the data is coming from model.json or GBO
  const pickupStartRestriction = restrictions.validPickupStartTime || restrictions.valid_pickup_start_time;
  const validPickupEndTimeRestriction = restrictions.validPickupEndTime || restrictions.valid_pickup_end_time;
  const rentalMaximumLengthRestriction = restrictions.rentalMaximumLength || restrictions.rental_maximum_length;
  const validReturnTimeRestriction = restrictions.validReturnTime || restrictions.valid_return_time;

  // For Last Minute Specials use validPickupEndTime and for Plan Ahead Specials use validPickupStartTime + rentalMaximumLength
  let pickupEndRestriction;
  if (pickupStartRestriction) {
    if (isPlanAhead) {
      pickupEndRestriction = gmiUtils.getDateTimeObj(pickupStartRestriction).add(rentalMaximumLengthRestriction, 'd');
    } else {
      pickupEndRestriction = validPickupEndTimeRestriction && gmiUtils.getDateTimeObj(validPickupEndTimeRestriction);
    }
  }

  const returnRestriction = isPlanAhead ? pickupEndRestriction : validReturnTimeRestriction;
  const hasRestrictions = pickupStartRestriction || pickupEndRestriction || returnRestriction;

  return hasRestrictions
    ? {
        validPickupStartTime: pickupStartRestriction && gmiUtils.getDateTimeObj(pickupStartRestriction),
        validPickupEndTime: pickupEndRestriction,
        validReturnTime: returnRestriction && gmiUtils.getDateTimeObj(returnRestriction),
      }
    : {};
};

export const isDateRestrictedByContract = (type, moment, restrictions) => {
  // restrictions object will have different naming convention depending if the data is coming from model.json or GBO
  const validPickUpStartTimeRestriction = restrictions?.validPickupStartTime || restrictions?.valid_pickup_start_time;
  const validPickUpEndTimeRestriction = restrictions?.validPickupEndTime || restrictions?.valid_pickup_end_time;

  if (gmiUtils.isObjectEmpty(restrictions)) {
    return false;
  }
  if (type === RESERVATIONS.EXCHANGE_TYPE_PICKUP) {
    return moment.isBefore(validPickUpStartTimeRestriction) || moment.isAfter(validPickUpEndTimeRestriction);
  }
  return moment.isBefore(validPickUpStartTimeRestriction) || moment.isAfter(validPickUpEndTimeRestriction);
};

export const getIsModifyRental = () => {
  const currentStepHash = getCurrentPageHash();
  return currentStepHash === RENTAL.RENTAL_VIEWS.DATE_TIME[0] || currentStepHash === RENTAL.RENTAL_VIEWS.COMMIT[0];
};

/**
 * Create displayable payment methods on confirmation based on the payment_type.
 * @param {Object} paymentMethodObject
 * @param {payment_type} type of payment method
 */
export function preparePaymentMethodsToDisplay(paymentMethod) {
  switch (paymentMethod.payment_type) {
    case PAYMENT.BUSINESS_ACCOUNT_APPLICANT:
      return {
        ...paymentMethod,
        label: i18n('about_your_payment_subtitle_one_content', {
          0: `(${paymentMethod.mask_billing_number || paymentMethod.masked_number})`,
        }),
      };
    case PAYMENT.VOUCHER:
      return {
        ...paymentMethod,
        label: `${paymentMethod.payment_type}: ${paymentMethod.account_name}`,
      };

    default:
      return {
        ...paymentMethod,
        label: i18n('about_your_payment_subtitle_one_content', {
          0: `(${paymentMethod.mask_billing_number || paymentMethod.masked_billing_account_number})`,
        }),
      };
  }
}
