import { getDeadTimeForSessionTimeout } from 'utils/date';
import utils from 'utils';
import { uuid } from 'utils/dom';
import { closeModal } from 'actions/modal/closeModal';
import { MODAL, RESERVATIONS } from 'constants';
import { openModalWithProps } from 'actions/modal/openModalWithProps';
import { getSessionTimeoutChannel } from 'utils/redirect';
import { SESSION_TIMEOUT_MODAL_CHANNEL_STATES } from 'constants/modals';

const { SESSION_TIMEOUT } = SESSION_TIMEOUT_MODAL_CHANNEL_STATES;

// Initialize the broadcast channel
const sessionTimeoutChannel = getSessionTimeoutChannel();

// Generate a unique ID for this tab instance
const TAB_INSTANCE_ID = uuid();

const showSessionTimeoutModalInternal = () => (dispatch) => {
  const getSessionTimeoutDeadlineValue = getDeadTimeForSessionTimeout();
  const TAB_ID_SESSION = utils.localStorage.getTabIdSession();
  sessionStorage.setItem(`pauseTimer_${TAB_ID_SESSION}`, false);

  // Since Canada French Redirection Modal stays open on user
  // redirect to the Canada French site, we need to close it before
  // opening the session timeout modal. Nothing will happen if this modal is not open.
  // and if other modals are open, flow stays the same.
  dispatch(closeModal(MODAL.CANADA_FRENCH_REDIRECTION_MODAL));

  dispatch(
    openModalWithProps(MODAL.SESSION_TIMEOUT_MODAL, {
      countDown: RESERVATIONS.GMA_SESSION_TIMEOUT_INFORMATION,
      runTimer: true,
      currentTime: getSessionTimeoutDeadlineValue,
    })
  );
};

export const showSessionTimeoutModal = (fromChannel) => (dispatch) => {
  const result = dispatch(showSessionTimeoutModalInternal());

  // Broadcast to other tabs if not already from a channel event
  if (!fromChannel) {
    sessionTimeoutChannel.postMessage({
      type: SESSION_TIMEOUT,
      senderId: TAB_INSTANCE_ID,
    });
  }

  return result;
};

export const getTabInstanceId = () => TAB_INSTANCE_ID;
