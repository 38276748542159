import { utils as gmiUtils } from '@ehi/global-marketing-interface';
import CURSORS from 'constants/cursors';
import { DATE } from 'constants';
import { i18n } from './i18n';
import { isMasked } from './validation';
//  Utils for working with solars date objects

const maxDayValue = 31;
const maxMonthValue = 12;
const yearLength = 4;

/**
 * isDaysTimesArrayEqual - checks if times on two days are equal, not including after hours
 *
 * @param ({moment object}, {solar date object} ) a moment object,
 *
 * @returns {boolean} if the store is closed or not
 */

export const isDaysTimesArrayEqual = (dateHashOne, dateHashTwo, hoursForStore) => {
  const lengthOfHoursArray = hoursForStore[dateHashOne]?.[CURSORS.STANDARD].hours.length;
  for (let i = 0; i < lengthOfHoursArray; i += 1) {
    //  This goes through the hours array and sees if we have different hours between two days
    const dayHashOne = hoursForStore[dateHashOne]?.[CURSORS.STANDARD].hours[i];
    const dayHashTwo = hoursForStore[dateHashTwo]?.[CURSORS.STANDARD].hours[i];
    if (dayHashOne && dayHashTwo && dayHashOne.open !== dayHashTwo.open && dayHashOne.close !== dayHashTwo.close) {
      return false;
    }
  }
  return true;
};

/**
 * validWeekDateRange - checks to see if a store is open any day in a week, starting with the given day
 *
 * @param ({moment object}, {solar date object} ),
 *
 * @returns {boolean} if the store is closed or not that week
 */

export const validWeekDateRange = (dateRange, hoursForStore) => {
  //  Check if we can forward or backwards
  for (let i = 0; i < 7; i += 1) {
    const dayInUseableFormat = gmiUtils.getDateTimeObjFromTs(dateRange).add(i, 'days');
    const hashForDate = dayInUseableFormat.format('YYYY-MM-DD');
    //  If it has times - don't care about if we have multiple - just take first index, we know the
    //  time is present then
    if (
      hoursForStore[hashForDate]?.[CURSORS.STANDARD].hours[0] ||
      hoursForStore[hashForDate]?.[CURSORS.STANDARD].open24Hours
    ) {
      //  We have a valid range
      return true;
    }
  }
  return false;
};

/**
 *  areDaysTimesSame - checks to see if on a given day the times are same between the days,
 *                    the inputs need to match the keys in the hoursForStore solar object
 *                    one can get this by using `.format('YYYY-MM-DD')` on the moment object
 * @param {string} Hash for day one, {string} Hash for day two, {object} solar hours object for store ),
 *
 * @returns {boolean} if the types are true
 */

export const areDaysTimesSame = (dateHashOne, dateHashTwo, hoursForStore) => {
  //  Three cases we check for: 24 hours, a single time in an array, or an multiple array of times
  //  Check if they are both 24 hours
  const hashDateOne = hoursForStore[dateHashOne]?.[CURSORS.STANDARD];
  const hasDateTwo = hoursForStore[dateHashTwo]?.[CURSORS.STANDARD];
  if (hashDateOne?.open24Hours && hasDateTwo?.open24Hours) {
    return true;
  }

  //  check if two days times are equal
  if (
    hashDateOne?.hours?.length &&
    hashDateOne?.hours?.length === hasDateTwo?.hours?.length &&
    hashDateOne?.hours[0].close === hasDateTwo?.hours[0].close
  ) {
    const lengthOfHoursArray = hashDateOne.hours.length;
    for (let i = 0; i < lengthOfHoursArray; i += 1) {
      if (!isDaysTimesArrayEqual(dateHashOne, dateHashTwo, hoursForStore)) {
        return false;
      }
    }
    return true;
  }
  return false;
};

/**
 * formatRemainingDays - Format remmaining days to pickup or return car following this rules:
 * If rental is +31 days away, no countdown will display
 * If rental is 8-30 days away, countdown will display in weeks (e.g. "Your return is 2 weeks away")
 * If rental is 1 day away, countdown will display "Return is 1 day away"
 * If rental is 0 day away or negative, countdown will display "Return Today"
 * If rental is 2-7 days away, countdown will display in days (e.g. "Your return is 2 days away")
 * @param {number} Number of days to be formated
 * @param {string} returnOrPickupString string to complete i18n key (pickup or return)
 * @returns {string} Formated date
 */

export const formatRemainingDays = (remainingDays, returnOrPickupString) => {
  if (remainingDays > 31) {
    return null;
  }

  if (remainingDays < 0) {
    return i18n('remaining_days_return_past_due', { 0: remainingDays });
  }

  if (remainingDays === 0) {
    return i18n(`remaining_days_${returnOrPickupString}_today`);
  }

  if (remainingDays === 1) {
    return i18n(`remaining_days_${returnOrPickupString}_in_one_day`);
  }
  if (remainingDays > 1 && remainingDays < 8) {
    return i18n(`remaining_days_${returnOrPickupString}_in_some_days`, { 0: remainingDays });
  }

  if (remainingDays >= 8 && remainingDays <= 31) {
    const weeksAway = Math.round(remainingDays / 7);
    return weeksAway === 1
      ? i18n(`remaining_days_${returnOrPickupString}_in_one_week`)
      : i18n(`remaining_days_${returnOrPickupString}_in_some_weeks`, { 0: weeksAway });
  }
  return null;
};

export const isValidDateFieldSetInput = ({ day = '', month = '', year = '' } = {}) => !!day && !!month && !!year;

export const getGBOFormatDateFromDateFieldSet = (dateObj, initialDateString = '') => {
  if (Object.values(dateObj).some(isMasked)) {
    return initialDateString;
  }
  return gmiUtils.getDateTimeObjFromDateParts({ ...dateObj, month: dateObj.month - 1 }).format('YYYY-MM-DD');
};

// a valid day value is either masked or between 1 and 31
export const isValidDay = (day) => isMasked(day) || (day >= 1 && day <= maxDayValue);

// a valid month value is either masked or between 1 and 12
export const isValidMonth = (month) => isMasked(month) || (month >= 1 && month <= maxMonthValue);

// a valid year value is either masked or should be 4 characters long
export const isValidYear = (year) => isMasked(year) || year?.length === yearLength;

// return a validation callback that checks a date part against its' validator
export const datePartValidation = (validationFn) => (value) => !validationFn(value) ? ' ' : null;

export const getDateFieldErrors = ({ day, month, year } = {}) => {
  const errors = {};
  /**
   * If any of the fields are empty, a required error is shown
   *  - this takes precedence over the "please check your date" error.
   * Either way, we want to highlight only the individual missing/invalid fields.
   */
  if (!day || !month || !year) {
    errors.empty = true;
    errors.day = !day;
    errors.month = !month;
    errors.year = !year;
  } else {
    errors.day = !isValidDay(day);
    errors.month = !isValidMonth(month);
    errors.year = !isValidYear(year);
  }

  return errors;
};

// This is for validating if the dateField is valid and getting the error object
const dateValidationState = (value) => {
  let valid = false;
  let errorState = {};
  if (value) {
    errorState = getDateFieldErrors(value);
    // Validate if all values are false. Invalid if has a true value
    valid = !Object.values(errorState).find(Boolean);
  }
  return { valid, errorState };
};

/**
 *  validateIssueAndExpiration - this function is for a record level validation for issue and expiration date fields.
 *  If shouldShowBothIssueAndExpirationDate = true, it should have at least one of those fields filled.
 *  If shouldShowBothIssueAndExpirationDate = false, it should validate the one that shouldShow = true.
 * @param {object} formData - FinalForm's inputs' values
 * @param {boolean} shouldShowBothIssueAndExpirationDate - value from countryData, true if both issue and expiration dates have FIELD_STATUS_OPTIONAL
 * @param {boolean} shouldShowIssueDate - value from countryData, true if issue date have FIELD_STATUS_MANDATORY
 * @param {boolean} shouldShowExpirationDate - value from countryData, true if expiration date have FIELD_STATUS_MANDATORY
 *
 * @returns {object} returns error object
 */
export const validateIssueAndExpiration = (
  formData,
  shouldShowBothIssueAndExpirationDate,
  shouldShowIssueDate,
  shouldShowExpirationDate
) => {
  const emptyErrorState = { valid: false, errorState: { empty: true, month: true, day: true, year: true } };

  const expirationValidation = formData?.license_expiration_date
    ? dateValidationState(formData.license_expiration_date)
    : emptyErrorState;
  const issueValidation = formData?.license_issue_date
    ? dateValidationState(formData.license_issue_date)
    : emptyErrorState;

  // Date fields validation for issue and expiration date when only one required but both accepted
  const isExpirationFieldValid = expirationValidation.valid;
  const isIssueFieldValid = issueValidation.valid;
  const shouldUpdateProfile = [isExpirationFieldValid, isIssueFieldValid].includes(true);

  // If should not show both dates, then should validate the one that is showing on the form, if needed
  const shouldValidateExpiration = shouldShowExpirationDate && !isExpirationFieldValid;
  const shouldValidateIssue = shouldShowIssueDate && !isIssueFieldValid;
  if (!shouldShowBothIssueAndExpirationDate && shouldValidateExpiration) {
    return { license_expiration_date: expirationValidation.errorState };
  }
  if (!shouldShowBothIssueAndExpirationDate && shouldValidateIssue) {
    return { license_issue_date: issueValidation.errorState };
  }

  // If should show both, then at least one should be filled
  if (!shouldUpdateProfile) {
    const error = {
      license_expiration_date: expirationValidation.errorState,
      license_issue_date: issueValidation.errorState,
    };
    return error;
  }
  return {};
};

/**
 *  isExpirationAndIssueFormStateNotValid - this function receives form data and check both issue and expiration error as well as if
 *  form submission has failed.
 * @param {object} form - FinalForm's form data
 *
 * @returns {boolean} if both issue and expiration are invalid and form submission failed
 */

export const isExpirationAndIssueFormStateNotValid = (form) => {
  const formState = form.getState();
  const { errors, submitFailed } = formState;
  const expirationError = errors?.license_expiration_date && !dateValidationState(errors.license_expiration_date).valid;
  const issueError = errors?.license_issue_date && !dateValidationState(errors.license_issue_date).valid;
  return expirationError && issueError && submitFailed;
};

/**
 *  formatDateTimeStampAndReturnDateTimeObj - formats the timestamp to remove hours and then returns the Moment date time object
 * @param {string} date - date timestamp (example: `Tue May 12 2020 00:00:00 GMT+0200 (Central European Summer Time)`)
 *
 * @returns {object} Moment date time object
 */

export const formatDateTimeStampAndReturnDateTimeObj = (date) => {
  // This line formats the timestamp like this: `Tue May 12 2020 00:00:00 GMT+0200 (Central European Summer Time)` => `2020-05-12`
  const timeStamp = gmiUtils.getDateTimeObjFromTs(date).format().slice(0, 10);

  return gmiUtils.getDateTimeObjFromTs(timeStamp);
};

/**
This function is used to get current datetime obj and adding 300 sec to show 5mins of timer when there is an inactivity for more than 15mins on the specific pages

* @returns {object} Moment date time object
*/

export const getDeadTimeForSessionTimeout = () => {
  const deadline = gmiUtils.getCurrentDateTimeObj();
  deadline.add(300, 'seconds');
  return deadline;
};

export const totalRemainingTime = (time) => Date.parse(time) - Date.parse(gmiUtils.getCurrentDateTimeObj());

export const getTimeRemainingForSessionTimeout = (endDateTime) => {
  const total = totalRemainingTime(endDateTime);
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / (1000 * 60)) % 60);

  return {
    total,
    minutes,
    seconds,
  };
};

/**
 * Used in generateTimeOptions() to disable invalid return times.
 * Ensures users cannot return a vehicle before picking it up.
 * Helps maintain valid booking times.
 * @param {Boolean} isSameDay - true if the startDate (pickup date) and endDate (return date) are the same.
 * @param {String} pickupTime - The time (in GMI_TIME_FORMAT) when the pickup is scheduled.
 * @param {object} currentTime - The time slot being checked against pickupTime.
 * @returns
 */
export const isSameDayBeforePickupHours = (isSameDay, pickupTime, currentTime) =>
  isSameDay &&
  pickupTime &&
  gmiUtils.getDateTimeObjFromTs(currentTime.format(DATE.GMI_TIME_FORMAT), DATE.GMI_TIME_FORMAT).isBefore(
    gmiUtils.getDateTimeObjFromTs(pickupTime, DATE.GMI_TIME_FORMAT) // Returns true if currentTime is before pickupTime.
  );
