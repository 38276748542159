/**
 * Date related constants (formating, min/max, etc)
 */
export default {
  DEFAULT_FORMATING: 'YYYY-MM-DD', // will return 2020-12-03
  AVAILABILITY_STATUS: {
    OPEN: 'OPEN',
    AFTER: 'AFTER',
    UNAVAILABLE: 'UNAVAILABLE',
  },
  GMI_TIME_FORMAT: 'HH:mm',
};
