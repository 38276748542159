import { createSelector } from 'reselect';
import { Map } from 'immutable';
import utils from 'utils';
import RESERVATIONS from 'constants/reservations';
import { carClassDetailsPath } from 'paths/carClassDetails';
import {
  getFilterValues,
  filteredFilterItemsSelector,
  sortedFilterItemsSelector,
  numFiltersSelectedFilterSelector,
  allFilterItemsSelector,
  getSelectedCurrencyToggle,
  getUiInitiatePickupLocation,
} from 'selectors/uiStateSelectors';

import { updateVehicleRatesSelectors, getSourceAndDestinationCurrencyValues } from 'selectors/currencyToggleSelectors';

import { pickupLocationCurrencyCodePath } from 'paths/reservationLocation';

import { utils as globalUtils } from '@ehi/global-marketing-interface';

import { appVehiclesListPath } from 'paths/app';
import { gmaInitiateStateCarClassCode } from 'paths/session';
import { gmaVehiclesPath } from 'paths/gma';
import { formattedPoliciesSelector } from './policiesSelectors';

const { PREPAY_CODE, PAYLATER_CODE } = RESERVATIONS;

export const gmaAllVehiclesList = (state) => utils.safeToJS(state.getIn(gmaVehiclesPath, Map({})));

export const getGmaInitiateCarClassCodesFromSession = (state) =>
  utils.safeToJS(state.getIn(gmaInitiateStateCarClassCode), []);

export const gmaVehiclesListBasedOnCountryCode = createSelector(
  [gmaAllVehiclesList, getUiInitiatePickupLocation],
  (vehiclesAvailability, pickupLocation) => {
    const uiPickupLocation = utils.safeToJS(pickupLocation, {});
    const vehiclesData = vehiclesAvailability;
    const countryCode = uiPickupLocation?.address?.country_code;
    return vehiclesData?.categories?.by_country?.[countryCode];
  }
);
const getSelectedVehicle = (state) => state.getIn(carClassDetailsPath);
const getCarClasses = (state) => state.getIn(['gmi', 'session', 'gbo', 'reservation', 'car_classes']);
const getReservationCoupons = (state) => state.getIn(['gmi', 'session', 'gbo', 'reservation', 'coupons']);
const getPreSelectedVehicle = (state) =>
  state.getIn(['gmi', 'session', 'gma', 'pre_selected', 'reservation', 'car_class_details']);
const getVehicleRates = (state, { vehicleCode }) =>
  state.getIn(['gmi', 'gma', 'reservation', 'car_class_details', vehicleCode, 'car_class_details', 'vehicle_rates']);
const getCurrentVehicleFilters = (state) => state.getIn(appVehiclesListPath);
const getCurrentLocationsAvailability = (state) => state.getIn(appVehiclesListPath);
export const getDestinationCurrencyCode = (state) => state.getIn(pickupLocationCurrencyCodePath);

// selectors
export const filteredResultsSelector = createSelector(
  [getFilterValues, filteredFilterItemsSelector, sortedFilterItemsSelector, numFiltersSelectedFilterSelector],
  (filters, filteredResults, filteredSorted, numFiltersSelected) => ({
    filteredResults,
    filteredSorted,
    hasFilters: !!filters,
    numFiltersSelected,
  })
);

export const isDestinationCurrencySelected = createSelector(
  [getDestinationCurrencyCode, getSelectedCurrencyToggle, getSourceAndDestinationCurrencyValues],
  (currency_code, selectedCurrency, sourceAndDestinationCurrencyValues) => {
    if (
      sourceAndDestinationCurrencyValues &&
      sourceAndDestinationCurrencyValues?.sourceCurrency?.code ===
        sourceAndDestinationCurrencyValues?.destinationCurrency?.code
    ) {
      return false;
    }
    return currency_code === selectedCurrency?.currency_code;
  }
);

/**
 * Gets the lowest total charge of a vehicle between its' Pre-pay and Pay-later main rates
 * @param {object} vehicle - Vehicle Data containing charges for either Pre-pay or Pay-later or both
 * @return {object} Charge object of the lowest total price between Pre-pay and Pay-later
 */
const calculateLowestTotalPrice = (vehicle, destinationCurrencySelected) => {
  const paynowTotal = destinationCurrencySelected
    ? vehicle?.charges?.PREPAY?.total_price_payment
    : vehicle?.charges?.PREPAY?.total_price_view;
  const paylaterTotal = destinationCurrencySelected
    ? vehicle?.charges?.PAYLATER?.total_price_payment
    : vehicle?.charges?.PAYLATER?.total_price_view;

  // a vehicle must have at least one charge type
  if (paylaterTotal && !paynowTotal) {
    return paylaterTotal;
  }

  if (paynowTotal && !paylaterTotal) {
    return paynowTotal;
  }

  // if it has both charge types, return the lowest one
  return parseFloat(paynowTotal.amount) > parseFloat(paylaterTotal.amount) ? paylaterTotal : paynowTotal;
};

/**
 * Gets the lowest rate charge of a vehicle between its' Pre-pay and Pay-later main rates
 * @param {object} vehicle - Vehicle Data containing charges for either Pre-pay or Pay-later or both
 * @return {object} Rate object of the lowest rate between Pre-pay and Pay-later
 */

// const calculateLowestRate = (vehicle, destinationCurrencySelected) => {
//   const paynowRate = vehicle?.charges?.PREPAY?.rates?.[0];
//   const paylaterRate = vehicle?.charges?.PAYLATER?.rates?.[0];

//   // a result must have at least one charge type
//   if (paylaterRate && !paynowRate) {
//     return paylaterRate;
//   }

//   if (paynowRate && !paylaterRate) {
//     return paynowRate;
//   }

//   // if it has both charge types, return the lowest one
//   return parseFloat(
//     destinationCurrencySelected ? paynowRate.unit_amount_payment.amount : paynowRate.unit_amount_view.amount
//   ) >
//     parseFloat(
//       destinationCurrencySelected ? paylaterRate.unit_amount_payment.amount : paylaterRate.unit_amount_view.amount
//     )
//     ? paylaterRate
//     : paynowRate;
// };

/**
 * Asserts whether the current vehicle charge is lower than the accumulated value of its' category
 * @param {object} vehicleLowestCost  - Lowest charge encountered for the current vehicle
 * @param {object} previousValue      - Previously accumulated charge for the vehicle's category
 * @return {boolean}  Indicating if the current vehicle lowest cost is lower than the previously
 *                    accumulated value for the same category
 */
const isCurrentCostLowerThanPreviousValue = (currentVehicleLowestCost, previousValue) => {
  const currentVehicleChargeAmount = currentVehicleLowestCost;
  const previousLowestChargeAmount = previousValue;
  // in case of incremental pricing, the value we have to check is inside unit_amount_view instead
  return parseFloat(currentVehicleChargeAmount.amount) < parseFloat(previousLowestChargeAmount.amount);
};

const reduceVehiclesCosts = (allResults, prepayExistsValue, destinationCurrencySelected, filterType) => {
  let prepayExistsResult = prepayExistsValue;
  const pricesReduced = allResults.reduce((acc, vehicle) => {
    if (vehicle) {
      if (filterType === 'car_drive' && vehicle.filters?.DRIVE?.filter_code === 'null') {
        return acc;
      }

      if (!prepayExistsResult && vehicle.charges?.[RESERVATIONS.PREPAY_CODE]) {
        prepayExistsResult = true;
      }
      let code;

      if (filterType === 'car_classes') {
        code = vehicle.category?.code;
      } else if (filterType === 'car_capacity') {
        code = vehicle.people_capacity;
      } else if (filterType === 'car_drive') {
        code = vehicle.filters?.DRIVE?.filter_code;
      }

      const vehicleLowestCost = calculateLowestTotalPrice(vehicle, destinationCurrencySelected);
      if (vehicleLowestCost && (!acc[code] || isCurrentCostLowerThanPreviousValue(vehicleLowestCost, acc[code]))) {
        acc[code] = vehicleLowestCost;
      }
      return acc;
    }

    return null;
  }, {});
  return { pricesReduced, prepayExistsResult };
};

/**
 * Function to fix prices in car capacity when a larger capacity has lower price than a smaller one
 * For example, car capacity for 4 seats lowest price = 4+ $30, and seven seats lowest price 7+ = $20
 * In this case, any car capacity lower than 7 should have the (Total from) price of $20 instead
 * since those lower capacities are going to include the 7+ capacity on results
 * @param {object} carCapacityFilterPrices  - Object of lowest prices for each car capacity
 * @return {boolean} - Object with fixed prices
 */
const reduceCarCapacityPrices = (carCapacityFilterPrices) => {
  const carCapacityFilterPricesObject = carCapacityFilterPrices;
  const carCapacityPricesAsArray = Object.keys(carCapacityFilterPricesObject).map((key) => ({
    key,
    ...carCapacityFilterPricesObject[key],
  }));

  carCapacityPricesAsArray.forEach((item, index) => {
    const lowestCarCapacityPrice = carCapacityPricesAsArray.reduce((prev, curr, currentIndex) =>
      currentIndex > index && parseFloat(prev.amount) < parseFloat(curr.amount) ? prev : curr
    );
    if (parseFloat(item.amount) > parseFloat(lowestCarCapacityPrice.amount)) {
      carCapacityFilterPricesObject[item.key] = lowestCarCapacityPrice;
    }
  });

  return carCapacityFilterPricesObject;
};

export const allResultsSelector = createSelector(
  [allFilterItemsSelector, isDestinationCurrencySelected],
  (allResults, destinationCurrencySelected) => {
    let prepayExists = false;
    const lowestCarFilterPrices = {};
    const carClassesReduced = reduceVehiclesCosts(allResults, prepayExists, destinationCurrencySelected, 'car_classes');
    prepayExists = carClassesReduced.prepayExistsResult;
    lowestCarFilterPrices.car_classes = carClassesReduced.pricesReduced;
    const carCapacityReduced = reduceVehiclesCosts(
      allResults,
      prepayExists,
      destinationCurrencySelected,
      'car_capacity'
    );
    prepayExists = carCapacityReduced.prepayExistsResult;
    lowestCarFilterPrices.car_capacity = reduceCarCapacityPrices(carCapacityReduced.pricesReduced);

    const carDriveReduced = reduceVehiclesCosts(allResults, prepayExists, destinationCurrencySelected, 'car_drive');
    prepayExists = carDriveReduced.prepayExistsResult;
    lowestCarFilterPrices.car_drive = carDriveReduced.pricesReduced;

    return { lowestCarFilterPrices, prepayExists };
  }
);

export const selectedVehicleSelector = createSelector([getSelectedVehicle], (selectedVehicle) =>
  utils.safeToJS(selectedVehicle)
);

export const preSelectedVehicleSelector = createSelector([getPreSelectedVehicle], (preSelectedVehicle) =>
  utils.safeToJS(preSelectedVehicle)
);

export const rateTypesSelector = createSelector(
  [getVehicleRates, getDestinationCurrencyCode, getSelectedCurrencyToggle],
  (vehicleRates, currency_code, selectedCurrency) => {
    let payLaterRates = utils.safeToJS(vehicleRates?.find((rate) => rate.get('charge_type') === PAYLATER_CODE));
    let prePayRates = utils.safeToJS(vehicleRates?.find((rate) => rate.get('charge_type') === PREPAY_CODE));
    if (selectedCurrency?.currency_code === currency_code) {
      if (payLaterRates) {
        payLaterRates = updateVehicleRatesSelectors(payLaterRates);
      }
      // Prepay rates
      if (prePayRates) {
        prePayRates = updateVehicleRatesSelectors(prePayRates);
      }
    }

    return {
      payLaterRates,
      prePayRates,
    };
  }
);

export const vehiclesHaveCouponSelector = createSelector(
  [getReservationCoupons, getCarClasses],
  (coupons, carClasses) => {
    const couponType = coupons?.getIn([0, 'type']);

    return {
      all:
        !!couponType &&
        !!carClasses &&
        carClasses.every((carClass) => carClass.getIn(['coupons', 0, 'type']) === couponType),
      none:
        !!couponType &&
        !!carClasses &&
        !carClasses.some((carClass) => carClass.getIn(['coupons', 0, 'type']) === couponType),
    };
  }
);

export const guaranteedVehiclesAvailableSelector = createSelector([getCarClasses], (carClasses) =>
  carClasses?.some((car) => car.get('guaranteed_vehicle'))
);

export const getSelectedVehiclesFilterSelector = createSelector([getCurrentVehicleFilters], (currentVehicleFilters) => {
  const { filters } = utils.safeToJS(currentVehicleFilters);
  return filters;
});

export const getSelectedCarClassCodes = createSelector([getCurrentVehicleFilters], (currentVehicleFilters) => {
  const { filters } = utils.safeToJS(currentVehicleFilters);
  return filters?.selectedVehicleCodes;
});

/**
 * Select applied vehicle class filters from session.
 *
 * @param      {object}  state   The redux state
 * @return     {Array}  The applied vehicle class filters from session
 */
export const selectAppliedVehicleClassFiltersFromSession = createSelector(
  [getGmaInitiateCarClassCodesFromSession],
  (data) => data
);

export const getLocationsAvailability = createSelector(
  [getCurrentLocationsAvailability],
  (currentLocationsAvailability) => utils.safeToJS(currentLocationsAvailability)
);

export const getNumOfSelectedVehiclesCountSelector = createSelector(
  [getCurrentVehicleFilters],
  (currentVehicleFilters) => {
    const { filters } = utils.safeToJS(currentVehicleFilters);
    return (globalUtils.isArrayNotEmpty(filters?.selectedValues) && filters?.numOfVehiclesSelected) || 0;
  }
);

const groupByCategories = (arr) =>
  arr.reduce((acc, cur) => {
    acc[cur.categories.category_code] = [...(acc[cur.categories.category_code] || []), cur];
    return acc;
  }, {});

export const getAllVehiclesListSelector = createSelector(
  [gmaVehiclesListBasedOnCountryCode, getSelectedVehiclesFilterSelector],
  (vehicleCategoriesList, initialVehiclesSelected) => {
    const vehicleData = vehicleCategoriesList;
    const hasVehicleCategoriesContainsSubcategoriesList =
      vehicleData?.vehicles && vehicleData.vehicles.filter((item) => Object.hasOwn(item.categories, 'subcategories'));
    const categories =
      hasVehicleCategoriesContainsSubcategoriesList && groupByCategories(hasVehicleCategoriesContainsSubcategoriesList);
    const initialSelectedVehicles = initialVehiclesSelected;
    const categoriesList = categories && Object.keys(categories);

    return (
      !!categoriesList &&
      categoriesList.map((category) => {
        const updateCheckedStateforVehiclesCategory = categories?.[category]?.map((vehicle) => {
          const filterInitialSelectedvehiclesBasedOnCode = initialSelectedVehicles?.selectedVehicleFilters
            ? initialSelectedVehicles?.selectedVehicleFilters[category]
            : [];
          const filterInitialSelectedVehicleClassCodes = initialSelectedVehicles?.selectedVehicleCodes
            ? initialSelectedVehicles.selectedVehicleCodes
            : [];

          const checkedStatus =
            !!filterInitialSelectedvehiclesBasedOnCode.includes(
              vehicle.categories?.subcategories?.[0].subcategory_name
            ) && filterInitialSelectedVehicleClassCodes.includes(vehicle.code);

          return (
            vehicle.categories?.subcategories && {
              category: vehicle?.categories,
              code: vehicle.code,
              make_model_or_similar_text: vehicle?.make_model_or_similar_text,
              name: vehicle?.categories?.subcategories?.[0].subcategory_name,
              subcategory_code: vehicle?.categories?.subcategories?.[0].subcategory_code,
              people_capacity: vehicle?.people_capacity,
              checked: checkedStatus,
            }
          );
        });

        return {
          code: category,
          label: categories[category]?.[0]?.categories.category_name,
          carClassChecked: false,
          vehicles:
            !!updateCheckedStateforVehiclesCategory && updateCheckedStateforVehiclesCategory.filter((item) => item),
        };
      })
    );
  }
);

// Selector to get the content for vehicle policies
// It takes the state and a vehicle object as input
// If the vehicle has a passenger van policy and it is required, it will find the corresponding policy content
// Otherwise, it will return the terms and conditions or description of the vehicle
export const getVehicleTermsContent = createSelector(
  [formattedPoliciesSelector, (state, vehicle) => vehicle],
  (formattedPolicies, vehicle) => {
    const policies = formattedPolicies;

    const { passenger_van_policy_required, passenger_van_policy_code, terms_and_conditions, description } = vehicle;
    let content = terms_and_conditions || description;
    // Custom content if we have a policy for the passenger van
    if (passenger_van_policy_required && passenger_van_policy_code && policies) {
      const vanPolicy = policies.find((policy) => policy.code === passenger_van_policy_code);

      if (vanPolicy) {
        content = vanPolicy.content;
      }
    }

    return content;
  }
);
