import { WINDOW_OBJECT_KEYS } from 'constants';
import { SESSION_TIMEOUT_MODAL_CHANNEL_STATES } from 'constants/modals';
import { config } from './config';
import { loadingOverlay } from './dom';

const { SESSION_TIMEOUT, INVALIDATE_SESSION } = SESSION_TIMEOUT_MODAL_CHANNEL_STATES;

/**
 * getUrl Utility to be used by redirect utility or passing fully formed url's
 * @module
 */
const getUrl = (href = '', options = {}) => `${window.location.origin}${href}${options.hash ? `#${options.hash}` : ''}`;

/**
 * Check that redirect key is the same as current page path
 * */
const checkKey = (key) => config.getRedirectUrl(key) === config.path_name;

/**
 * Check that the key(s) passed in matches against the current page params supplied by AEM.
 * @param {Array|String} keys
 * @returns {boolean}
 */
const isCurrPathByRedirectKeys = (keys) => {
  if (typeof keys === 'string') {
    return checkKey(keys);
  }
  if (Array.isArray(keys)) {
    return keys.some(checkKey);
  }
  return false;
};

/**
 * Redirect Utility
 * @module
 */
export const goTo = (href = '', options = {}) => window.location.assign(getUrl(href, options));
/**
 * Handles redirect object provided in GMA responses
 * @param {Object} redirect {type, path, hash}
 */
export const gmaRedirect = (redirect) => {
  const { parent, children = [] } = redirect;
  const mappedPath = config.getRedirectUrl(parent);
  // TODO: This is temporary until hash format is established
  const hash = `/${children.join('/').toLowerCase()}`;
  if (isCurrPathByRedirectKeys(mappedPath)) {
    history.pushState({}, hash); // TODO: this history is not defined anywhere, shouldn't it break?
  } else {
    goTo(mappedPath, { hash });
  }
};

export const externalRedirect = (route, toLowerCase = true, noHttp = true) => {
  const safeRoute = toLowerCase ? route.toLowerCase() : route;

  window.location.replace(noHttp ? `${window.location.protocol}//${safeRoute}` : safeRoute);
};

let customRedirect = null;
let sessionTimeoutChannel;

if (typeof BroadcastChannel !== 'undefined') {
  // Browser supports BroadcastChannel
  sessionTimeoutChannel = new BroadcastChannel('alamo-session-timeout');
} else {
  // Browser doesn't support BroadcastChannel, create a no-op implementation
  sessionTimeoutChannel = {
    postMessage: () => {
      console.debug('BroadcastChannel not supported in this browser');
    },
    close: () => {},
    addEventListener: () => {
      console.debug('BroadcastChannel addEventListener not supported in this browser');
    },
    removeEventListener: () => {
      console.debug('BroadcastChannel removeEventListener not supported in this browser');
    },
  };
}

const setCustomRedirect = (redirectValue) => {
  customRedirect = redirectValue;
};

const goToTimeoutPage = () => {
  goTo(config.getRedirectUrl(customRedirect || WINDOW_OBJECT_KEYS.TIMEOUT));
};

/**
 * Handles timer for gma session timeout, redirects to timeout page
 */
export const timeoutRedirect = {
  timer: null,
  setCustomRedirect,
  start: (timeoutFn) => {
    if (timeoutRedirect.timer) {
      clearTimeout(timeoutRedirect.timer);
    }
    let timeoutMinutes = config.getTimeoutMinutes();
    // Setting default timeout value if we don't have timeout config seetup value
    timeoutMinutes = timeoutMinutes || 20;

    const timeoutMilliseconds = config.getTimeoutMinutesInMilliSeconds(timeoutMinutes - 5);
    timeoutRedirect.timer = setTimeout(() => {
      // Broadcast timeout event to all tabs
      sessionTimeoutChannel.postMessage({ type: SESSION_TIMEOUT });
      return timeoutFn ? timeoutFn() : null;
    }, timeoutMilliseconds);
  },

  invalidateSession: (timeoutFn) => {
    // Broadcast invalidate session to all tabs
    sessionTimeoutChannel.postMessage({ type: INVALIDATE_SESSION });
    return timeoutFn ? timeoutFn().then(goToTimeoutPage) : goToTimeoutPage();
  },
};

export const getSessionTimeoutChannel = () => sessionTimeoutChannel;

/**
 * Redirect to a Not Found page (any 404 for AEM)
 */
export const redirectToNotFound = () => {
  const homePageUrl = config.getRedirectUrl(WINDOW_OBJECT_KEYS.HOME_PAGE_URL);
  const basePath = homePageUrl.substring(0, homePageUrl.lastIndexOf('/'));
  goTo(`${basePath}/not-found.html`);
};

/**
 * Sets up the loading overlay and redirects the user to an internal page,
 *
 * @param {string} redirectKey - Redirect key as setup in AEM; one of `WINDOW_OBJECT_KEYS`
 * @param {boolean} showLoadingOverlay - indicates whether should apply/skip the loading overlay (defaults to true)
 * @param {string|null} hash - hash to a SPA specific step/page
 * @param {URLSearchParams|null} searchParams - hash to a SPA specific step/page
 */
export const loadingRedirect = (redirectKey, showLoadingOverlay = true, hash = null, searchParams = null) => {
  if (showLoadingOverlay) {
    loadingOverlay(true);
  }

  const url = new URL(window.location.origin);
  url.pathname = config.getRedirectUrl(redirectKey);

  if (hash) {
    url.hash = hash;
  }

  if (searchParams) {
    searchParams.forEach((value, key) => url.searchParams.append(key, value));
  }

  window.location.assign(url.href);
};
