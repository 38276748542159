const damPath = '/content/dam/alamo';

export default {
  ABOUT_PAYMENT_CARD_ICON: `${damPath}/functional/icons/illustration-secondary-payment-sized.png`,
  CREDIT_CARD_AMERICANEXPRESS: `${damPath}/functional/icons/payment-logos-cc-amex@3x.png`,
  CREDIT_CARD_MASTERCARD: `${damPath}/functional/icons/payment-logos-cc-mastercard@3x.png`,
  CREDIT_CARD_VISA: `${damPath}/functional/icons/payment-logos-cc-visa@3x.png`,
  CREDIT_CARD_DISCOVER: `${damPath}/functional/icons/payment-logos-cc-discover@3x.png`,
  CREDIT_CARD_BLANK: `${damPath}/functional/icons/payment-logos-cc-blank@3x.png`,
  CUSTOMER_SUPPORT_1: `${damPath}/functional/icons/customer-support.png`,
  CUSTOMER_SUPPORT_2: `${damPath}/functional/icons/customer-support@2x.png`,
  CUSTOMER_SUPPORT_3: `${damPath}/functional/icons/customer-support@3x.png`,
  DEALS_EMAIL_SPECIALS_MODALS: `${damPath}/functional/icons/email-signup.png`,
  GOOGLE_MAP_AIRPORT: `${damPath}/functional/icons/cut-assets-map-pin-google-airport.png`,
  GOOGLE_MAP_BRANCH: `${damPath}/functional/icons/cut-assets-map-pin-google-branch.png`,
  GOOGLE_MAP_RAIL: `${damPath}/functional/icons/cut-assets-map-pin-google-rail.png`,
  KEY_RENTAL_FACTS_1: `${damPath}/functional/icons/key-rental-facts.png`,
  KEY_RENTAL_FACTS_2: `${damPath}/functional/icons/key-rental-facts@2x.png`,
  KEY_RENTAL_FACTS_3: `${damPath}/functional/icons/key-rental-facts@3x.png`,
  RENTAL_POLICIES_PLACEHOLDER: `${damPath}/functional/icons/rental-policies-placeholder.png`,
  HELPFUL_TIPS_ICON: `${damPath}/functional/icons/helpful-tips.png`,
  RESERVATION_CHECKED_IN_1: `${damPath}/functional/icons/vehicle-checked-in.png`,
  RESERVATION_CHECKED_IN_2: `${damPath}/functional/icons/vehicle-checked-in@2x.png`,
  RESERVATION_CHECKED_IN_3: `${damPath}/functional/icons/vehicle-checked-in@3x.png`,
  PROFILE_NO_UPCOMING_TRIPS_1: `${damPath}/functional/icons/cut-assets-content-gps.png`,
  PROFILE_NO_UPCOMING_TRIPS_2: `${damPath}/functional/icons/cut-assets-content-gps@2x.png`,
  PROFILE_NO_UPCOMING_TRIPS_3: `${damPath}/functional/icons/cut-assets-content-gps@3x.png`,
  NO_UPCOMING_TRIPS_1: `${damPath}/functional/icons/gps.png`,
  NO_UPCOMING_TRIPS_2: `${damPath}/functional/icons/gps@2x.png`,
  NO_UPCOMING_TRIPS_3: `${damPath}/functional/icons/gps@3x.png`,
  ALAMO_INSIDERS_1: `${damPath}/marketing/ehi-brands-logos/alamo-insiders.png`,
  ALAMO_INSIDERS_2: `${damPath}/marketing/ehi-brands-logos/alamo-insiders@2x.png`,
  ALAMO_INSIDERS_3: `${damPath}/marketing/ehi-brands-logos/alamo-insiders@3x.png`,
  INSIDERS_VEHICLE_1: `${damPath}/functional/icons/insiders.png`,
  INSIDERS_VEHICLE_2: `${damPath}/functional/icons/insiders@2x.png`,
  INSIDERS_VEHICLE_3: `${damPath}/functional/icons/insiders@3x.png`,
  NO_PAST_TRIPS: `${damPath}/functional/illustrations/illustration-circle-receipts.svg`,
  NO_PAST_TRIPS_1: `${damPath}/functional/icons/receipts.png`,
  NO_PAST_TRIPS_2: `${damPath}/functional/icons/receipts@2x.png`,
  NO_PAST_TRIPS_3: `${damPath}/functional/icons/receipts@3x.png`,
  PICKUP_INSTRUCTIONS: `${damPath}/functional/icons/pick-up-instructions.png`,
  STC_ELIGIBLE_LOCATIONS_1: `${damPath}/functional/illustrations/missing-rewards.png`,
  STC_ELIGIBLE_LOCATIONS_2: `${damPath}/functional/illustrations/missing-rewards@2x.png`,
  STC_ELIGIBLE_LOCATIONS_3: `${damPath}/functional/illustrations/missing-rewards@3x.png`,
  STC_CONTENT_NO_COUNTER: `${damPath}/functional/icons/bypass-complete.png`,
  ADDITIONAL_DRIVER_CONFETTI: `${damPath}/functional/illustrations/additional-drivers-confetti@3x.png`,
  AD_CHOICES: `${damPath}/marketing/non-ehi-logos/ad-choices.png`,
  DEALS_1: `${damPath}/functional/icons/illustration-secondary-deals.png`,
  DEALS_2: `${damPath}/functional/icons/illustration-secondary-deals@2x.png`,
  DEALS_3: `${damPath}/functional/icons/illustration-secondary-deals@3x.png`,
};
