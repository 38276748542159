/**
 * ROOT
 * */
export const baseSessionPath = ['gmi', 'session'];

/**
 * GBO Paths
 * */
export const gboSessionPath = [...baseSessionPath, 'gbo']; // root

export const gboUnauthPastTripsPath = [...gboSessionPath, 'past_trips'];
export const gboProfilePath = [...gboSessionPath, 'profile'];

// gbo > reservation
export const gboReservationPath = [...gboSessionPath, 'reservation'];
export const gboReservationMessagesPath = [...gboReservationPath, 'messages'];
export const gboReservationConfirmationNumber = [...gboReservationPath, 'confirmation_number'];
export const collectNewPaymentCardModifyPath = [...gboReservationPath, 'collect_new_payment_card_in_modify'];
export const reservationBarcodePath = [...gboReservationPath, 'reservation_barcode'];
export const autoCheckInFlagPath = [...gboReservationPath, 'auto_check_in'];
export const keyFactsPoliciesPath = [...gboReservationPath, 'key_facts_policies'];
export const reservationExternalLinksPath = [...gboReservationPath, 'links'];
export const reservationStatusPath = [...gboReservationPath, 'reservation_status'];
export const reservationRentalStatePath = [...gboReservationPath, 'rental_state'];
export const tourVoucherReservationPath = [...gboReservationPath, 'tour_voucher_reservation'];
export const secretRatePath = [...gboReservationPath, 'secret_rate'];

// gbo > partner_reward_program
export const gboPartnerRewardProgramPath = [...gboReservationPath, 'partner_reward_program'];

/**
 * GMA Paths
 * */
export const gmaSessionPath = [...baseSessionPath, 'gma']; // root

export const gmaTrueModifyPath = [...gmaSessionPath, 'modify'];
export const gmaReservationPath = [...gmaSessionPath, 'reservation'];
export const commitReservationRequestPath = [...gmaReservationPath, 'commit_reservation_request'];
export const allowedResScreensPath = [...gmaSessionPath, 'allowed_res_screens'];
export const gmaDeeplinkPath = [...gmaSessionPath, 'deeplink'];
export const gmaLoggedInPath = [...gmaSessionPath, 'logged_in'];
export const gmaKeepMeLoggedInPath = [...gmaSessionPath, 'keep_me_logged_in_enabled'];
export const gmaShowDriveTypeFilterPath = [...gmaSessionPath, 'show_drive_type_filter'];

// gma > pre_selected
export const gmaPreSelectedPath = [...gmaSessionPath, 'pre_selected'];
export const gmaPreSelectedReservationPath = [...gmaPreSelectedPath, 'reservation'];
export const gmaPreSelectedMessagesPath = [...gmaPreSelectedPath, 'messages'];

// gma > initiate_request
export const gmaInitiateRequestPath = [...gmaSessionPath, 'initiate_request'];
export const oneWayRentalGmaPath = [...gmaInitiateRequestPath, 'one_way_rental'];
export const gmaInitiatePickupLocation = [...gmaInitiateRequestPath, 'pickup_location'];
export const gmaInitiateReturnLocation = [...gmaInitiateRequestPath, 'return_location'];
export const gmaInitiatePickupTimePath = [...gmaInitiateRequestPath, 'pickup_time'];
export const gmaInitiateReturnTimePath = [...gmaInitiateRequestPath, 'return_time'];
export const gmaInitiateStateCarClassCode = [...gmaInitiateRequestPath, 'car_class_codes'];
/**
 * Analytics Session Paths
 * */
export const analyticsSessionPath = [...baseSessionPath, 'analytics']; // root

export const gmaInidividualMaskEmailPath = [
  ...gmaSessionPath,
  'non_loyalty_unsubscribe_individual',
  'individual',
  'mask_email',
];
