/* eslint-disable import/newline-after-import */

import utils from '@ehi/global-marketing-interface/utils';
import { compose } from 'redux';
import {
  createUrl,
  getCurrentPagePath,
  getModelJsonPathWithQueryString,
  getModelJsonPath,
  isPathCurrentUrl,
  setParams,
  replaceUrlParamsInBrackets,
  queryObject,
  getCurrentPageHash,
} from './url';
import { scopeTab } from './accessibility/scopeTab';
import { findTabbableDescendants, a11yFocusOutline, getNextTabbable } from './accessibility/tabbable';
import { formatKeys } from './accessibility/keys';
import {
  domReady,
  focusWithoutScroll,
  hasAttributeInScope,
  uuid,
  loadingOverlay,
  scrollPage,
  autoPageTop,
  isTouchEvent,
  isMouseEvent,
  isContainedInSixColumnGrid,
  isAtStartOfStandAloneBookingWidgetPage,
  onFocusScrollToTop,
} from './dom';
import { i18n } from './i18n';
import { config } from './config';
import {
  safeExecute,
  sanitize,
  composeValidators,
  required,
  isTrueBool,
  mustBeNumber,
  greaterThan,
  hasValidHours,
  hasInvalidDay,
  hasError,
  email,
  phone,
  checkPhoneCode,
  isMasked,
  getIfNotMasked,
  noRequiredErrors,
  runOnSuccessResponse,
  altPhoneNumberValidation,
} from './validation';
import { withClickOutside } from './withClickOutside';
import {
  locationsMarkerMapper,
  googleMapBoundsToSolr,
  convertKilometersToMiles,
  convertMilesToKilometers,
  addressObjToSingleLine,
  getGoogleMapsURL,
  getGasStationURL,
  getLocationCompleteName,
  findCountryCode,
} from './locations';
import {
  getNextOrFirstElement,
  getPreviousOrLastElement,
  isValidIndex,
  arrayToObjectByKey,
  sortByKey,
  mergeTwo,
  moveArrayIndex,
  sortArray,
} from './array';
import { toJS, withForwardedRef, OnUpdateWrapper } from './hoc';
import { safeToJS } from './immutable';
import { pluralHandler } from './pluralHandler';
import { scrollLock } from './scrollLock';
import { goTo, gmaRedirect, externalRedirect, timeoutRedirect, redirectToNotFound, loadingRedirect } from './redirect';
import {
  getDistanceUnit,
  getTransmissionDescriptionFromFeaturesArray,
  getImageFromImagesObject,
  getTaxesFeesTotal,
  isFreeUpgradeAvailable,
  isAlphabetic,
} from './vehicles';
import { trimSpaceFromStringValueOfObject, getKeyByValue, get, objectHasOwnProperty } from './object';
import {
  getFormattedPrice,
  getFormattedZeroPrice,
  getVehicleRateSavingsArray,
  formatTimeAndDistance,
  isPriceDifferenceRefund,
} from './prices';
import {
  analyticsError,
  analyticsPaymentSession,
  analyticsDispatchErrorEvent,
  analyticsClearErrors,
  analyticsInteraction,
  analyticsPrepareKey,
  determineType,
  dispatchEventWithDOM,
  dtm,
  initAnalytics,
  mergeGmaIntoAnalytics,
  mergeSolrIntoAnalytics,
  reduxEvent,
  analyticsFormKey,
} from './analytics';
import {
  storeReservationLocalStorage,
  getReservationLocalStorage,
  deleteReservationLocalStorage,
  storeGlobalGatewayPreferredDomain,
  getGlobalGatewayPreferredDomain,
  getTabIdSession,
  deleteSessionStorageKey,
} from './localStorage';
import storageManager from './storageManager';
import {
  validWeekDateRange,
  areDaysTimesSame,
  isDaysTimesArrayEqual,
  formatRemainingDays,
  isValidDateFieldSetInput,
  getGBOFormatDateFromDateFieldSet,
  getDateFieldErrors,
  isValidDay,
  isValidMonth,
  isValidYear,
  datePartValidation,
  validateIssueAndExpiration,
  isExpirationAndIssueFormStateNotValid,
} from './date';
import { stringUnderscorer, capitalize, capitalizeAll } from './string';
import localization from './localization';
import { isSafeNumberVal, getIntegerNumber } from './number';
import {
  profileProgramMatchPRP,
  getPhoneByPriority,
  formatPhoneData,
  isDuplicateEmailError,
  formatPhoneNumber,
  unformatPhoneNumber,
  formatPhoneDataBasedOnCountryCode,
} from './profile';
import { getStepsContent, parseStepCounter, parseProfileUpdatePayload } from './checkin';
import { getSkipTheCounterStepsContent, parseSkipTheCounterStepCounter } from './skipTheCounter';
import { isInternetExplorer11, isMobile } from './browser';
import print from './print';
import { driverProfilePayloadParser, contactInfoPayloadParser } from './driverProfilePayloadParser';
import { isReservationFlow, preparePaymentMethodsToDisplay, isInResFlowReviewPage } from './reservation';
import cookie from './cookie';
import { trapFocus } from './trapFocus';
import { getModelJson } from './aem';
import {
  isUnsupportedMask,
  parseMaskToVMasker,
  countMaskDigits,
  parseCountryInfo,
  isValidCountryPhoneNumber,
  getRawPhoneNumber,
  selectedCountryDetails,
  isDialingCode,
  getMaskedPhoneNumber,
  getMaskedEmail,
} from './phoneInput';

/**
 * @namespace "utils"
 */

export default {
  gmi: utils,
  string: { stringUnderscorer, capitalize, capitalizeAll },
  aem: {
    getModelJson,
  },
  accessibility: {
    scopeTab,
    findTabbableDescendants,
    formatKeys,
    a11yFocusOutline,
    getNextTabbable,
  },
  locations: {
    locationsMarkerMapper,
    googleMapBoundsToSolr,
    convertMilesToKilometers,
    convertKilometersToMiles,
    addressObjToSingleLine,
    getGoogleMapsURL,
    getGasStationURL,
    getLocationCompleteName,
    findCountryCode,
  },
  fieldValidation: {
    composeValidators,
    required,
    isTrueBool,
    mustBeNumber,
    greaterThan,
    hasValidHours,
    hasInvalidDay,
    hasError,
    email,
    phone,
    checkPhoneCode,
    isMasked,
    getIfNotMasked,
    noRequiredErrors,
    altPhoneNumberValidation,
  },
  date: {
    validWeekDateRange,
    areDaysTimesSame,
    isDaysTimesArrayEqual,
    formatRemainingDays,
    isValidDateFieldSetInput,
    getGBOFormatDateFromDateFieldSet,
    getDateFieldErrors,
    isValidDay,
    isValidMonth,
    isValidYear,
    datePartValidation,
    validateIssueAndExpiration,
    isExpirationAndIssueFormStateNotValid,
  },
  dom: {
    domReady,
    focusWithoutScroll,
    hasAttributeInScope,
    uuid,
    loadingOverlay,
    scrollPage,
    autoPageTop,
    isTouchEvent,
    isMouseEvent,
    isContainedInSixColumnGrid,
    isAtStartOfStandAloneBookingWidgetPage,
    onFocusScrollToTop,
  },
  array: {
    sortByKey,
    getNextOrFirstElement,
    getPreviousOrLastElement,
    isValidIndex,
    arrayToObjectByKey,
    mergeTwo,
    moveArrayIndex,
    sortArray,
  },
  analytics: {
    init: initAnalytics,
    error: analyticsError,
    paymentSession: analyticsPaymentSession,
    dispatchErrorEvent: analyticsDispatchErrorEvent,
    dispatchEventWithDOM,
    clearErrors: analyticsClearErrors,
    interaction: analyticsInteraction,
    mergeGmaIntoAnalytics,
    mergeSolrIntoAnalytics,
    determineType,
    dtm,
    reduxEvent,
    prepareKey: analyticsPrepareKey,
    analyticsFormKey,
  },
  i18n,
  safeExecute,
  runOnSuccessResponse,
  sanitize,
  withClickOutside,
  config,
  toJS,
  withForwardedRef,
  OnUpdateWrapper,
  safeToJS,
  pluralHandler,
  scrollLock,
  goTo,
  gmaRedirect,
  externalRedirect,
  timeoutRedirect,
  redirectToNotFound,
  loadingRedirect,
  getDistanceUnit,
  getTransmissionDescriptionFromFeaturesArray,
  getImageFromImagesObject,
  getTaxesFeesTotal,
  getFormattedPrice,
  getFormattedZeroPrice,
  isPriceDifferenceRefund,
  formatTimeAndDistance,
  preparePaymentMethodsToDisplay,
  isInResFlowReviewPage,
  getVehicleRateSavingsArray,
  isFreeUpgradeAvailable,
  isAlphabetic,
  browser: {
    isInternetExplorer11,
    isMobile,
  },
  checkin: {
    getStepsContent,
    parseStepCounter,
    parseProfileUpdatePayload,
  },
  skipTheCounter: {
    getSkipTheCounterStepsContent,
    parseSkipTheCounterStepCounter,
  },
  localStorage: {
    storeReservationLocalStorage,
    getReservationLocalStorage,
    deleteReservationLocalStorage,
    storeGlobalGatewayPreferredDomain,
    getGlobalGatewayPreferredDomain,
    getTabIdSession,
    deleteSessionStorageKey,
  },
  storageManager,
  compose,
  localization,
  number: {
    isSafeNumberVal,
    getIntegerNumber,
  },
  object: {
    trimSpaceFromStringValueOfObject,
    getKeyByValue,
    get,
    objectHasOwnProperty,
  },
  profile: {
    profileProgramMatchPRP,
    getPhoneByPriority,
    formatPhoneData,
    driverProfilePayloadParser,
    contactInfoPayloadParser,
    isDuplicateEmailError,
    formatPhoneNumber,
    unformatPhoneNumber,
    formatPhoneDataBasedOnCountryCode,
  },
  url: {
    createUrl,
    getCurrentPagePath,
    getModelJsonPathWithQueryString,
    getModelJsonPath,
    isPathCurrentUrl,
    setParams,
    replaceUrlParamsInBrackets,
    queryObject,
    getCurrentPageHash,
  },
  print,
  isReservationFlow,
  cookie,
  trapFocus,
  phoneInput: {
    isUnsupportedMask,
    parseMaskToVMasker,
    countMaskDigits,
    parseCountryInfo,
    isValidCountryPhoneNumber,
    getRawPhoneNumber,
    selectedCountryDetails,
    isDialingCode,
    getMaskedPhoneNumber,
    getMaskedEmail,
  },
};
